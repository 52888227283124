import {
  AffiliationOverviewFragment,
  GetUserQuery,
  useGetAllAffiliationOverviewQuery,
  useGetAllFinancingObjectsQuery,
  useUserIdentificationQuery,
} from '@generated';
import identity from 'lodash/identity';
import orderBy from 'lodash/orderBy';
import { useEffect } from 'react';
import { getHubspotAppId } from 'src/environment/hubspot';
import { identify } from 'src/services/analytics/analyticsActions';
import { loadHubspotChat } from 'src/services/loadHubspotChat';
import { extractNonNullItems } from 'src/utils/extractNonNullItems';

function getTraits(
  affiliations: AffiliationOverviewFragment[] | undefined | null,
  advisorEmail: string | undefined | null
) {
  if (affiliations) {
    const primaryCompany = affiliations[0]
      ? {
          id: affiliations[0].company?.uuid,
          name: affiliations[0].company?.name,
          status: affiliations[0].company?.company_status,
          isPublic: !!affiliations[0].company?.ticker,
        }
      : null;
    const allCompaniesNames = affiliations
      .map((item) => item?.company?.name)
      .filter(identity)
      .join(', ');

    return {
      advisor: advisorEmail ?? null,
      companies: allCompaniesNames ?? null,
      company: primaryCompany ?? null,
    };
  }
  return {};
}

const identifyCallLocalStorageKey = 'identifiedUserUuid-v3';

export function useIdentify(user: GetUserQuery['user']) {
  const identifiedUserUuid = sessionStorage.getItem(
    identifyCallLocalStorageKey
  );

  const customerUuid = user?.customer?.uuid ?? '';
  const nickname = user?.customer?.nickname ?? '';

  const {
    data: affiliationsData,
    loading: isLoadingA,
  } = useGetAllAffiliationOverviewQuery({
    variables: { customerUuid },
    skip: !customerUuid,
  });
  const {
    data: financingData,
    loading: isLoadingF,
  } = useGetAllFinancingObjectsQuery({
    variables: { filters: { owner: user?.uuid } },
    skip: !customerUuid,
  });

  const {
    data: tokenData,
    loading: isLoadingToken,
  } = useUserIdentificationQuery({
    variables: user
      ? {
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
        }
      : undefined,
    skip: !user,
  });

  useEffect(() => {
    const isMissingUser = user === null || user === undefined;
    if (isLoadingA || isLoadingF || isMissingUser || isLoadingToken) {
      return;
    }
    if (!affiliationsData?.affiliations || !financingData?.financingObjects) {
      return;
    }

    const hubspotAppId = getHubspotAppId();

    loadHubspotChat(hubspotAppId, tokenData?.userIdentification.token, user);

    const affiliations = (affiliationsData?.affiliations?.nodes || []).filter(
      identity
    );
    const affiliationsUuidList = affiliations.map(
      (affiliation) => affiliation?.uuid
    );
    // this filtering is needed, because BE leaves orphaned financing objects when affiliation gets deleted
    const financingObjectsList = extractNonNullItems(
      financingData?.financingObjects?.nodes
    ).filter((item) => {
      const requestAffiliationUuid = item.proposal_request?.affiliation?.uuid;

      return typeof requestAffiliationUuid === 'string'
        ? affiliationsUuidList.includes(requestAffiliationUuid)
        : false;
    });
    const lastSubmittedRequest = orderBy(
      financingObjectsList,
      ['proposal_request.date_submitted'],
      ['desc']
    )[0];

    const otherTraits = getTraits(
      affiliations,
      lastSubmittedRequest?.advisor?.public_email
    );

    // same user - no need to identify twice
    if (identifiedUserUuid === user.uuid) {
      return;
    }

    identify(user, {
      ...otherTraits,
      nickname,
    });

    sessionStorage.setItem(identifyCallLocalStorageKey, user.uuid);
  }, [
    isLoadingA,
    isLoadingF,
    affiliationsData,
    financingData,
    user,
    identifiedUserUuid,
    tokenData,
    isLoadingToken,
  ]);
}
