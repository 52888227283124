import { useEffect } from 'react';
import { emailVerificationWording } from 'src/+auth/constants/wording';
import { useQueryParams } from 'src/hooks/useQueryParams/useQueryParams';
import { useNotificationContext } from 'src/providers/NotificationProvider/useNotificationContext';
import { AuthenticationQueryKeys } from 'src/types/authenticationKeys';
import { NotificationType } from 'src/types/notification';
import { parseAxiosErrorMessage, tryParseJson } from 'src/utils/errors';

export const useSocialLoginErrorHandler = () => {
  const { showNotification } = useNotificationContext();

  const [error] = useQueryParams(AuthenticationQueryKeys.ErrorDescription);

  useEffect(() => {
    if (error) {
      showNotification(
        NotificationType.Error,
        parseAxiosErrorMessage(
          tryParseJson(error),
          emailVerificationWording.warning
        )
      );
    }
  }, [error, showNotification]);
};
