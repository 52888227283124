import { useEmailVerification } from 'src/+auth/hooks/useEmailVerification/useEmailVerification';
import { useIdentify } from 'src/+auth/hooks/useIdentify/useIdentify';
import { useSocialLoginErrorHandler } from 'src/+auth/hooks/useSocialLoginErrorHandler/useSocialLoginErrorHandler';

import { useAuthenticatedUser } from './hooks';

export const AuthenticationHelper = () => {
  const { user } = useAuthenticatedUser();

  useEmailVerification();
  useSocialLoginErrorHandler();
  useIdentify(user);

  return null;
};
