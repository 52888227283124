import { EnvironmentName } from './environmentName';

export const getHubspotAppId = () => {
  switch (import.meta.env.VITE_DEPLOYMENT_ENV) {
    case EnvironmentName.Production:
      return import.meta.env.VITE_HUBSPOT_APP_ID;
    case EnvironmentName.Staging:
    default:
      return import.meta.env.HUBSPOT_SANDBOX_APP_ID;
  }
};
