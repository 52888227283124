import { Box, Button, Typography } from '@secfi/components';
import { render } from 'micromustache';
import React, { useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import {
  TCanvasConfettiAnimationOptions,
  TCanvasConfettiInstance,
} from 'react-canvas-confetti/dist/types';
import { confettiPopupWording } from 'src/features/postIpoConfetti/constants/wording';

const options: TCanvasConfettiAnimationOptions = {
  particleCount: 200,
  spread: 500,
  origin: { y: 0 },
};

interface Props {
  handleClick: () => void;
  companyName: string;
}
export const ConfettiPopup = ({ handleClick, companyName }: Props) => {
  const instance = useRef<TCanvasConfettiInstance>();

  const onInitHandler = ({ confetti }: { confetti: TCanvasConfettiInstance }) =>
    (instance.current = confetti);

  useEffect(() => {
    const prefersReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)'
    ).matches;

    if (instance.current && !prefersReducedMotion) {
      instance.current({
        ...options,
      });
    }
  }, []);

  return (
    <Box maxWidth={450}>
      <ReactCanvasConfetti onInit={onInitHandler} />
      <Typography mb={3} variant="3xl">
        {render(confettiPopupWording.title, {
          company:
            companyName.charAt(companyName.length - 1).toLowerCase() === 's'
              ? `${companyName}'`
              : `${companyName}'s`,
        })}
      </Typography>
      <Typography mb={4} whiteSpace="pre-line">
        {confettiPopupWording.content}
      </Typography>
      <Button
        fullWidth
        trackingId="closed-confetti-popup"
        variant="contained"
        onClick={handleClick}
        color="secondary"
        size="large"
      >
        {confettiPopupWording.button}
      </Button>
    </Box>
  );
};
