import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { emailVerificationWording } from 'src/+auth/constants/wording';
import { useAsync } from 'src/hooks/useAsync';
import { useQueryParams } from 'src/hooks/useQueryParams/useQueryParams';
import { useNotificationContext } from 'src/providers/NotificationProvider/useNotificationContext';
import { useRestApiClient } from 'src/services/apiClients/RestApiClientContext';
import { AuthenticationQueryKeys } from 'src/types/authenticationKeys';
import { NotificationType } from 'src/types/notification';
import { parseAxiosErrorMessage } from 'src/utils/errors';

export const useEmailVerification = () => {
  const restApiClient = useRestApiClient();
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [verificationToken] = useQueryParams(
    AuthenticationQueryKeys.VerifyEmailToken
  );

  const { error: verifyEmailError } = useAsync(
    () => restApiClient.verifyEmail(verificationToken ?? ''),
    [verificationToken],
    {
      executeOnMount: !!verificationToken,
      onSuccess() {
        navigate({ ...location, search: '' }, { replace: true });
        showNotification(
          NotificationType.Success,
          emailVerificationWording.success
        );
      },
      onError() {
        showNotification(
          NotificationType.Error,
          parseAxiosErrorMessage(
            verifyEmailError as AxiosError,
            emailVerificationWording.error
          )
        );
      },
    }
  );
};
