import { useEffect, useRef } from 'react';
import { useNotificationContext } from 'src/providers/NotificationProvider/useNotificationContext';
import { useAuthenticationServiceState } from 'src/services/authentication/hooks';

export const InitErrorMessage = () => {
  const { initError } = useAuthenticationServiceState();
  const hasShownToast = useRef(false);

  const { showNotification } = useNotificationContext();

  useEffect(() => {
    if (initError && !hasShownToast.current) {
      showNotification(initError.intent, initError.message);
      hasShownToast.current = true;
    }
  }, [initError]);

  return null;
};
