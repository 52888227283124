import { ApolloProvider } from '@apollo/client';
import { withEmotionCache } from '@emotion/react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from '@remix-run/react';
import { LinksFunction } from '@remix-run/server-runtime';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import NProgress from 'nprogress';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { ToastRenderer } from 'src/components/ToastRenderer/ToastRenderer';
import { ASSETS_URL } from 'src/environment/assets';
import { PostIpoConfettiFeature } from 'src/features/postIpoConfetti/PostIpoConfettiFeature';
import { CalendlyProvider } from 'src/providers/CalendlyProvider/CalendlyProvider';
import { CartaConnectionProvider } from 'src/providers/CartaProvider/CartaProvider';
import { DialogProvider } from 'src/providers/DialogProvider/DialogProvider';
import { LinkProvider } from 'src/providers/LinkProvider/LinkProvider';
import { NotificationProvider } from 'src/providers/NotificationProvider/NotificationProvider';
import { PageMetaProvider } from 'src/providers/PageMetaProvider/PageMetaProvider';
import { ProgressBarProvider } from 'src/providers/ProgessBarProvider/ProgessBarProvider';
import { SidePanelProvider } from 'src/providers/SidePanelProvider/SidePanelProvider';
import { ThemeProvider } from 'src/providers/ThemeProvider/ThemeProvider';
import { ApolloSdkProvider } from 'src/services/apiClients/ApolloSdkContext';
import { RestApiClientProvider } from 'src/services/apiClients/RestApiClientContext';
import { AuthenticationHelper } from 'src/services/authentication/AuthenticationHelper';
import { AuthenticationServiceProvider } from 'src/services/authentication/AuthenticationServiceContext';
import {
  apolloClient,
  apolloSdk,
  authenticationService,
  restApiClient,
} from 'src/shared';
import 'swiper/css';
import { InitErrorMessage } from '~/components/InitErrorMessage/InitErrorMessage';
import { NavigationProgress } from '~/components/NavigationProgress/NavigationProgress';
import { RedirectAfterLogin } from '~/components/RedirectAfterLogin/RedirectAfterLogin';
import {
  GoogleAnalyticsBody,
  GoogleAnalyticsHead,
} from '~/utils/google-analytics';

import { useReinjectEmotionCache } from './emotion';
import npStyles from './styles/nprogress.css?url';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(minMax);

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
  parent: '.header-with-menu',
});

const GTM_ID = import.meta.env.VITE_GTM_ID;

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === '[object regexp]'
    ) {
      return this.replace(str, newStr as string);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr as string);
  };
}

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: npStyles }];
};

export const Layout = withEmotionCache(
  ({ children }: { children: React.ReactNode }, emotionCache) => {
    useReinjectEmotionCache(emotionCache);

    return (
      <html lang="en">
        <head>
          <GoogleAnalyticsHead id={GTM_ID} />
          <title>Secfi</title>

          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            name="description"
            content="Make the most of your equity. We create equity planning tools that can help you make
    informed financial decisions from offer to IPO"
          />

          <meta name="robots" content="noindex" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Secfi" />
          <meta
            name="twitter:description"
            content="Make the most of your equity. We create equity planning tools that can help
    you make informed financial decisions from offer to IPO"
          />
          <meta
            name="twitter:image:src"
            content="https://www.secfi.com/dashboard/assets/secfi-600x600.png"
          />

          <meta property="og:title" content="Secfi" />
          <meta
            property="og:description"
            content="Make the most of your equity. We create equity planning tools that can help
    you make informed financial decisions from offer to IPO"
          />
          <meta
            property="og:image"
            content="https://www.secfi.com/dashboard/assets/secfi-opengraph.png"
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:url" content="https://www.secfi.com/" />
          <meta property="og:site_name" content="Secfi" />
          <meta name="referrer" content="origin-when-cross-origin" />
          <meta property="og:type" content="website" />
          <link
            rel="preconnect"
            href="https://assets.secfi.com"
            crossOrigin=""
          />
          <link rel="icon" type="image/png" href="/assets/favicon.svg" />
          <link rel="stylesheet" href="/index.css" />
          <link
            rel="stylesheet"
            href="//fonts.googleapis.com/icon?family=Material+Icons"
          />
          <link
            rel="stylesheet"
            href="https://unpkg.com/swiper@9.0.5/swiper.min.css"
          />
          <link
            rel="stylesheet"
            href="https://unpkg.com/swiper@9.0.5/modules/pagination/pagination.min.css"
          />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <Meta />
          <Links />
        </head>
        <body>
          <GoogleAnalyticsBody id={GTM_ID} />
          {children}
          <ScrollRestoration />
          <Scripts />
        </body>
      </html>
    );
  }
);

export const AppProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <CookiesProvider>
      <ApolloProvider client={apolloClient}>
        <PageMetaProvider>
          <SidePanelProvider>
            <DialogProvider>
              <ProgressBarProvider>
                <CalendlyProvider>
                  <NotificationProvider>
                    <LinkProvider>{children}</LinkProvider>
                  </NotificationProvider>
                </CalendlyProvider>
              </ProgressBarProvider>
            </DialogProvider>
          </SidePanelProvider>
        </PageMetaProvider>
      </ApolloProvider>
    </CookiesProvider>
  );
};

export default function App() {
  return (
    <AppProviders>
      <ApolloSdkProvider value={apolloSdk}>
        <RestApiClientProvider value={restApiClient}>
          <AuthenticationServiceProvider value={authenticationService}>
            <AuthenticationHelper />
            <PostHogProvider client={posthog}>
              <ThemeProvider>
                <PostIpoConfettiFeature />
                <CartaConnectionProvider>
                  <Outlet />
                  <NavigationProgress />
                  <RedirectAfterLogin />
                </CartaConnectionProvider>
                <ToastRenderer />
                <InitErrorMessage />
              </ThemeProvider>
            </PostHogProvider>
          </AuthenticationServiceProvider>
        </RestApiClientProvider>
      </ApolloSdkProvider>
    </AppProviders>
  );
}

export function HydrateFallback() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // body has margin 8px by default, so we need to subtract it from 100vh
        height: 'calc(100vh - 16px)',
      }}
    >
      <div
        style={{
          width: '100px',
          height: '100px',
          margin: '0 auto',
          backgroundImage: `url('${ASSETS_URL}images/gif/secfi-loader-small.gif')`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
        role="status"
        aria-busy="true"
        aria-live="polite"
        data-testid="loading-spinner"
      />
    </div>
  );
}
