import React from 'react';
import { ClientOnly } from '~/utils/client-only';

interface GoogleAnalyticsProps {
  id: string;
}

export const GoogleAnalyticsHead = ({ id }: GoogleAnalyticsProps) => {
  return (
    <ClientOnly>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
              'gtm.start':
                new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${id}');`,
        }}
      ></script>
    </ClientOnly>
  );
};

export const GoogleAnalyticsBody = ({ id }: GoogleAnalyticsProps) => {
  return (
    <ClientOnly>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${id}`}
          height="0"
          width="0"
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </ClientOnly>
  );
};
