import { useNavigate } from '@remix-run/react';
import * as React from 'react';
import { getParsedRedirectAfterLogin } from 'src/hooks/useRedirectAfterLogin';
import { useAuthenticatedUser } from 'src/services/authentication/hooks';

/**
 * Redirects the user to the page they were trying to access before they were
 * redirected to the login page.
 */
export const RedirectAfterLogin = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticatedUser();

  const isAuthenticated = !!user;

  React.useEffect(() => {
    if (isAuthenticated) {
      const parsedRedirect = getParsedRedirectAfterLogin();
      if (parsedRedirect) {
        // we use requestAnimationFrame to ensure that the navigation happens
        // after the current render cycle, otherwise the routes might not be
        // ready yet
        requestAnimationFrame(() => {
          navigate(parsedRedirect, { replace: true });
        });
      }
    }
  }, [isAuthenticated, navigate]);

  return null;
};
