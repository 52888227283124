import { InfoIcon, useMediaQueries } from '@secfi/components';
import { palette } from '@secfi/styles';
import React, { FC, useCallback } from 'react';
import toast from 'react-hot-toast';
import { WarningIcon } from 'src/+portfolio/components/HoverableTableError/WarningIcon';
import {
  NotificationType,
  ShowAsyncNotification,
} from 'src/types/notification';

import { NotificationContext } from './NotificationContext';

const notificationStyles: Record<NotificationType, React.CSSProperties> = {
  [NotificationType.Error]: {
    border: `solid 1px ${palette.red[200]}`,
    background: palette.red[50],
  },
  [NotificationType.Success]: {
    border: `solid 1px ${palette.green[200]}`,
    background: palette.green[50],
  },
  [NotificationType.Warn]: {
    border: `solid 1px ${palette.yellow[200]}`,
    background: palette.yellow[50],
  },
  [NotificationType.Info]: {
    border: `solid 1px ${palette.blue[200]}`,
    background: palette.blue[50],
  },
};

export const NotificationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isMobile } = useMediaQueries();

  const maxWidth = isMobile ? '100%' : '400px';
  const commonStyles = { maxWidth };

  const showNotification = useCallback(
    (type: NotificationType, text: string) => {
      // no built in toast for warn
      if (type === NotificationType.Warn) {
        toast(text, {
          style: {
            ...commonStyles,
            ...notificationStyles[type],
          },
          icon: <WarningIcon />,
          iconTheme: {
            primary: palette.yellow[500],
            secondary: palette.yellow[200],
          },
        });
        return;
      }

      if (type === NotificationType.Info) {
        toast(text, {
          style: {
            ...commonStyles,
            ...notificationStyles[type],
          },
          icon: <InfoIcon />,
          iconTheme: {
            primary: palette.blue[500],
            secondary: palette.blue[200],
          },
        });
        return;
      }

      toast[type](text, {
        style: {
          ...commonStyles,
          ...notificationStyles[type],
        },
      });
    },
    [isMobile]
  );

  const showAsyncNotification: ShowAsyncNotification = useCallback(
    async (promise, options) => {
      return toast.promise(
        promise,
        {
          loading: options.loading || 'Loading...',
          success: options.success || 'Success',
          error: options.error || 'Error',
        },
        {
          style: {
            ...commonStyles,
            ...options.style,
          },
          success: {
            style: notificationStyles[NotificationType.Success],
          },
          error: {
            style: notificationStyles[NotificationType.Error],
          },
          loading: {
            style: notificationStyles[NotificationType.Warn],
          },
        }
      );
    },
    [
      isMobile,
      notificationStyles,
      commonStyles,
      NotificationType.Error,
      NotificationType.Success,
      NotificationType.Warn,
    ]
  );

  return (
    <NotificationContext.Provider
      value={{ showNotification, showAsyncNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
