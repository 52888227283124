import isEqual from 'lodash/isEqual';
import React, { FC, ReactNode, useState } from 'react';
import { DialogOptions } from 'src/types/dialogs';

import { DialogContext, ShowDialog } from './DialogContext';
import { modalDialogAnimationTransitionTime } from './constants';

export const DialogProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [content, setContent] = useState<ReactNode | null>(null);
  const [options, setOptions] = useState<DialogOptions | null>(null);

  const showDialog: ShowDialog = (_content, _options = {}) => {
    setIsDialogVisible(true);
    setOptions(_options);
    setContent(_content);
  };

  function hideDialog(hideDialogOptions?: DialogOptions) {
    setIsDialogVisible(false);
    const currentOptions = hideDialogOptions
      ? hideDialogOptions
      : options ?? null;
    if (currentOptions?.onCloseCallback) {
      currentOptions.onCloseCallback();
    }
    // add timeout to allow animation to complete
    setTimeout(() => {
      setContent((contentAfterTimeout) => {
        // Since a new dialog might be shown before the timeout expires
        // we want to be sure that we are erasing the _old_ content
        if (contentAfterTimeout !== content) {
          // This is a new dialog, no need to erase it
          return contentAfterTimeout;
        } else {
          return null;
        }
      });
      setOptions((optionsAfterTimeout) => {
        // Since a new dialog might be shown before the timeout expires
        // we want to be sure that we are erasing the _old_ options
        if (!isEqual(optionsAfterTimeout, currentOptions)) {
          // This is a new dialog, no need to erase it
          return optionsAfterTimeout;
        } else {
          return null;
        }
      });
    }, modalDialogAnimationTransitionTime);
  }

  const contextValue = {
    isDialogVisible,
    content,
    options,
    showDialog,
    hideDialog,
  };

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
    </DialogContext.Provider>
  );
};
