import {
  Company,
  useGetCustomerFlagLazyQuery,
  useSetCustomerFlagMutation,
} from '@generated';
import dayjs from 'dayjs';
import React, { useCallback, useEffect } from 'react';
import {
  suppressIpoBannerKeyName,
  suppressIpoBannerKeyValue,
} from 'src/constants/signupWithCarta';
import { useGetUserAffiliations } from 'src/hooks/useGetUserAffiliations';
import { useDialogContext } from 'src/providers/DialogProvider/hooks/useDialogContext';
import { useAuthenticatedUser } from 'src/services/authentication/hooks';
import { HAS_SEEN_CONFETTI_DIALOG } from 'src/types/customerFlag';
import { isPublicCompanyFragment } from 'src/utils/isPublicCompanyFragment';

import { ConfettiPopup } from './components/ConfettiPopup/ConfettiPopup';

export const PostIpoConfettiFeature = () => {
  const { user, isMirroring } = useAuthenticatedUser();
  const customerUuid = user?.customer?.uuid || '';
  const { showDialog, hideDialog } = useDialogContext();

  const { affiliations, isLoading } = useGetUserAffiliations();
  const ipoAffiliation = affiliations.find((a) =>
    isPublicCompanyFragment(a?.company)
  );

  const payload =
    customerUuid && ipoAffiliation
      ? {
          customer: customerUuid,
          affiliation: ipoAffiliation.uuid,
          flag: HAS_SEEN_CONFETTI_DIALOG,
        }
      : undefined;

  const [setCustomerFlag] = useSetCustomerFlagMutation();
  const [getCustomerFlag] = useGetCustomerFlagLazyQuery();

  const handleShowDialog = useCallback(
    async (
      company: Pick<Company, 'ipo_date' | 'ipo_lockup_end_date' | 'name'>
    ) => {
      if (payload && company) {
        const { data } = await getCustomerFlag({
          variables: payload,
        });

        const showBanner = !data?.getCustomerFlag?.flag;

        // if company has a lockup period end date, use that as the banner deadline;
        // otherwise, use the ipo_date and add 6 months to it
        const endDate = company.ipo_lockup_end_date
          ? dayjs(company.ipo_lockup_end_date)
          : dayjs(company.ipo_date).add(6, 'months');
        // if today is before the `ipo_lockup_end_date` or the `ipo_date` + 6 months,
        // then show the banner
        const isWithinTimeFrame = dayjs().isBefore(endDate);
        if (showBanner && isWithinTimeFrame) {
          // if we've just signed up with Carta, we don't want to show the banner
          if (
            sessionStorage.getItem(suppressIpoBannerKeyName) ===
            suppressIpoBannerKeyValue
          ) {
            await setCustomerFlag({ variables: payload });
            sessionStorage.removeItem(suppressIpoBannerKeyName);
            return;
          }

          showDialog(
            <ConfettiPopup
              companyName={company.name}
              handleClick={handleDismiss}
            />,
            {
              disableBackdropClose: true,
            }
          );
        }
      }
    },
    [ipoAffiliation?.uuid]
  );

  useEffect(() => {
    if (!isLoading && ipoAffiliation && isMirroring === false) {
      handleShowDialog(ipoAffiliation?.company);
    }
  }, [ipoAffiliation, isLoading, isMirroring]);

  const handleDismiss = () => {
    // create a new flag for the user and affiliation, so it won't be shown again
    setCustomerFlag({
      variables: payload,
      onCompleted: () => hideDialog(),
    });
  };

  return null;
};
