import React, { FC, useState } from 'react';

import {
  ProgressBarContext,
  UpdateProgressBarState,
} from './ProgessBarContext';

export const ProgressBarProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [progressBarState, setProgressBarState] = useState({
    progressValue: 0,
    isVisible: false,
  });

  const updateState: UpdateProgressBarState = (newState) => {
    setProgressBarState({
      ...progressBarState,
      ...newState,
    });
  };

  const contextValue = {
    isVisible: progressBarState.isVisible,
    progressValue: progressBarState.progressValue,
    updateState,
  };

  return (
    <ProgressBarContext.Provider value={contextValue}>
      {children}
    </ProgressBarContext.Provider>
  );
};
