import React, { FC, useState } from 'react';
import { CalendlyOptions } from 'src/types/calendly';

import { CalendlyContext } from './CalendlyContext';

export const CalendlyProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [options, setOptions] = useState<CalendlyOptions | null>(null);

  const contextValue = {
    closePopup: () => {
      setOptions(null);
    },
    showPopup: (options: CalendlyOptions) => {
      setOptions(options);
    },
    options,
    isPopupVisible: !!options,
  };

  return (
    <CalendlyContext.Provider value={contextValue}>
      {children}
    </CalendlyContext.Provider>
  );
};
