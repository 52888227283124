import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { arrakisTheme } from '@secfi/styles';
import React, { FC } from 'react';

export const ThemeProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <MuiThemeProvider theme={arrakisTheme({})}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);
