import { captureException, withScope } from '@sentry/remix';
import { EnvironmentName } from 'src/environment/environmentName';

export const loadHubspotChat = (
  appId: string | undefined,
  token: string | undefined,
  user: { email: string }
) => {
  if (
    !appId &&
    import.meta.env.VITE_DEPLOYMENT_ENV === EnvironmentName.Production
  ) {
    withScope((scope) => {
      scope.setTransactionName('loadHubspotChat');
      scope.setFingerprint(['loadHubspotChat']);
      captureException(new Error('Hubspot appId is not defined'));
    });
  }

  if (!window.HubSpotConversations) {
    // Hubspot tracking code url
    const src = `//js-eu1.hs-scripts.com/${appId}.js`;
    const script = document.querySelector(`script[src="${src}"]`);

    if (!script) {
      const loadChat = () => {
        const script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = src;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      };
      window.hsConversationsSettings = {
        identificationEmail: user.email,
        identificationToken: token,
      };
      loadChat();
    }
  }
};
