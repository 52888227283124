import React, { FC, useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import {
  PageMetaContext,
  PageMetaState,
  PageMetaValues,
  initialPageMetaData,
} from './PageMetaContext';

export const PageMetaProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { pathname } = useLocation();

  const previousPathRef = useRef<string>('');

  const [pageMetaData, setPageMetaData] = useState<PageMetaValues>(
    initialPageMetaData
  );

  useEffect(() => {
    if (previousPathRef.current !== pathname) {
      previousPathRef.current = pathname;
    }
  }, [pathname]);

  const setPageMeta = (newMetaData: Partial<PageMetaValues>) => {
    setPageMetaData((current) => ({
      ...current,
      ...newMetaData,
    }));
  };

  const resetPageMeta = () => {
    setPageMetaData({ ...initialPageMetaData });
  };

  const contextValue: PageMetaState = {
    ...pageMetaData,
    previousPath: previousPathRef.current,
    setPageMeta,
    resetPageMeta,
  };

  return (
    <PageMetaContext.Provider value={contextValue}>
      <HelmetProvider>
        <Helmet>
          <title>{pageMetaData.title}</title>
          <meta name="description" content={pageMetaData.description} />
        </Helmet>
      </HelmetProvider>
      {children}
    </PageMetaContext.Provider>
  );
};
