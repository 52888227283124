import { DrawerProps } from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';

import { SidePanelContext } from './SidePanelContext';

export const SidePanelProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isSidePanelVisible, setIsSidePanelVisible] = useState(false);
  const [content, setContent] = useState<ReactNode | null>(null);
  const [drawerProps, setDrawerProps] = useState<DrawerProps>({
    anchor: 'left',
    onKeyUp: (event) => {
      if (event.key === 'Escape') {
        hideSidePanel();
      }
    },
  });

  const showSidePanel = (_content: ReactNode, _drawerProps?: DrawerProps) => {
    setIsSidePanelVisible(true);
    setContent(_content);
    if (_drawerProps) {
      setDrawerProps({
        ...drawerProps,
        ...(_drawerProps || {}),
      });
    }
  };

  function hideSidePanel() {
    setIsSidePanelVisible(false);
  }

  const contextValue = {
    isSidePanelVisible,
    content,
    drawerProps,
    showSidePanel,
    hideSidePanel,
  };

  return (
    <SidePanelContext.Provider value={contextValue}>
      {children}
    </SidePanelContext.Provider>
  );
};
