import { createContext, useContext } from 'react';

import type { SecfiApolloSdk } from './createApolloSdk';

export const ApolloSdkContext = createContext<SecfiApolloSdk | null>(null);

export const ApolloSdkProvider = ApolloSdkContext.Provider;

export const useApolloSdk = (): SecfiApolloSdk => {
  const client = useContext(ApolloSdkContext);
  if (client === null) {
    throw new Error(
      'no ApolloSdk found. make sure to wrap the app in <ApolloSdkProvider>'
    );
  }
  return client;
};
